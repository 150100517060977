import api from './api'; // 引入共享的 axios 实例

const ChatbotService = {
    query: async (query) => {
        const formData = new FormData();
        formData.append('query', query);

        const response = await api.post('/query/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    },
};

export default ChatbotService;
