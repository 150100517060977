import React, { useContext } from 'react';
import { Box, Container, Typography } from '@mui/material';
import CustomAppBar from '../../components/shared-components/AppBar';
import { SettingsContext } from '../../context/SettingsContext'; // 引入 SettingsContext
import Footer from '../../components/shared-components/Footer';
import MarkdownViewer from "../../components/shared-components/MarkdownViewer";

const Notes = () => {
  const { language, themeMode } = useContext(SettingsContext); // 获取当前语言

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ minHeight: '100vh',}}>
        {/* 顶部导航 */}
        <CustomAppBar />

        {/* 主内容区域 */}
        <Box
          sx={{
            flex: 1, // 占满剩余空间
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start', // 内容从顶部开始
            mt: { xs: 2, md: 12}, // 与导航栏分隔
            px: { xs: 2, sm: 4, md: 2 }, // 响应式内边距
            overflow: 'auto', // 滚动行为
          }}
        >
          <Box
            sx={{
              maxWidth: '900px', // 最大宽度
              width: '100%', // 自适应宽度
              pl: { xs: "2%", sm: "5%", md: "8%"},
              pr: { xs: "2%", sm: "5%", md: "8%" },
              mt: { xs: 10, md: 0 },
              overflow: 'auto', // 添加滚动
            }}
          >
            {/* 根据语言动态渲染 MarkdownViewer */}
            {language === 'cn' ? (
              <MarkdownViewer filePath="cn" target="note_readme" />
            ) : (
              <MarkdownViewer filePath="en" target="note_readme" />
            )}
          </Box>
        </Box>
      </Box>
      
      {/* 下部 Footer */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'background.default',
          borderTop: (theme) => ({
            xs: 'none', // 在 xs 屏幕无边框
            md: `1px solid ${theme.palette.divider}`, // 在 md 屏幕及以上显示边框
          }),
          maxWidth: (theme) => theme.breakpoints.values.lg, // 限制最大宽度为 lg
          width: '100%', // 确保宽度是 100% 的父元素宽度
          mx: 'auto', // 水平居中
          mt: {md: 3},
        }}
      >
        <Footer />
      </Box>
    </Box>
  );
};

export default Notes;
