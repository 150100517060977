import React from 'react';
import { UserProvider} from './context/UserContext'; // 引入 UserProvider 和 useUser
import { SettingsProvider } from './context/SettingsContext';
import AppRoutes from './routes/AppRoutes';


function App() {
  return (
    <SettingsProvider>
      <UserProvider>
        <AppRoutes />
      </UserProvider>
    </SettingsProvider>
  );
}

export default App;