// 首先需要安装以下依赖:
// npm install @mui/material @mui/icons-material @emotion/react @emotion/styled

import React from 'react';
import { 
  Container,
  Box,
  Typography,
  Button,
  Paper
} from '@mui/material';
import { 
  Home as HomeIcon,
  ArrowBack as ArrowBackIcon 
} from '@mui/icons-material';

const NotFound = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        bgcolor: 'background.default'
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={3}
          sx={{
            py: 6,
            px: 4,
            textAlign: 'center',
            borderRadius: 2
          }}
        >
          {/* 404 数字 */}
          <Typography
            variant="h1"
            component="h1"
            sx={{
              fontSize: '8rem',
              fontWeight: 700,
              color: 'primary.main',
              mb: 2
            }}
          >
            404
          </Typography>

          {/* 错误信息 */}
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            sx={{ fontWeight: 500 }}
          >
            Not Found
          </Typography>

          <Typography
            variant="body1"
            color="text.secondary"
            paragraph
            sx={{ mb: 4 }}
          >
            抱歉，您访问的页面不存在。
          </Typography>

          {/* 按钮组 */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: 2,
              justifyContent: 'center'
            }}
          >
            <Button
              variant="contained"
              startIcon={<HomeIcon />}
              onClick={() => window.location.href = '/'}
              sx={{ minWidth: 150 }}
            >
              Home - 首页
            </Button>
            
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => window.history.back()}
              sx={{ minWidth: 150 }}
            >
              Back - 返回
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default NotFound;