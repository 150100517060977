import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import CustomAppBar from '../../components/shared-components/AppBar';
import { SettingsContext } from '../../context/SettingsContext';
import Footer from '../../components/shared-components/Footer';

const Profile = () => {
  const { language, themeMode} = useContext(SettingsContext); // 获取当前语言

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* 上部 100vh 的部分 */}
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CustomAppBar />
        <Box sx={{ mt: 8, textAlign: 'center' }}>
          <Typography variant="h4" gutterBottom>
            {language === 'en' ? (
              <>
                Profile! <br />
                Still Working on it!
              </>
            ) : (
              <>
                个人简介！ <br />
                再等等，还在弄，别催！
              </>
            )}
          </Typography>
        </Box>
      </Box>

      {/* 下部 Footer */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'background.default',
          borderTop: (theme) => ({
            xs: 'none', // 在 xs 屏幕无边框
            md: `1px solid ${theme.palette.divider}`, // 在 md 屏幕及以上显示边框
          }),
          maxWidth: (theme) => theme.breakpoints.values.lg, // 限制最大宽度为 lg
          width: '100%', // 确保宽度是 100% 的父元素宽度
          mx: 'auto', // 水平居中
        }}
      >
        <Footer />
      </Box>

      
    </Box>
  );
};

export default Profile;
