import React, { useState, useRef, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  TextField, 
  IconButton, 
  List, 
  ListItem, 
  ListItemText,
  Container 
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import BotAvatar from '@mui/icons-material/SmartToy';
import ChatbotService from '../../services/ChatbotService';

const ChatbotCard = () => {
  const [messages, setMessages] = useState([
    { text: "This is my chatbot. Feel free to ask me anything about myself.", sender: 'bot' }
  ]);
  const [inputText, setInputText] = useState('');
  const [loading, setLoading] = useState(false);
  const latestMessageRef = useRef(null); // Ref for the latest message
  const isInitialRender = useRef(true); // Ref to track initial render

  const handleSendMessage = async () => {
    if (inputText.trim() === '') return;

    // Check if inputText exceeds 500 characters
    if (inputText.length > 250) {
      const errorReply = {
        text: "Your query exceeds the maximum allowed length of 250 characters. Please shorten it.",
        sender: 'bot',
      };
      setMessages((prevMessages) => [...prevMessages, errorReply]);
      return;
    }

    // Add user message
    const userMessage = { text: inputText, sender: 'user' };
    setMessages(prevMessages => [...prevMessages, userMessage]);

    // Clear input and set loading state
    setInputText('');
    setLoading(true);

    try {
      // Call backend API to get bot response
      const response = await ChatbotService.query(inputText);

      const botReply = {
        text: response.response || "I couldn't process your query. Please try again.",
        sender: 'bot'
      };

      // Add bot reply
      setMessages(prevMessages => [...prevMessages, botReply]);
    } catch (error) {
      console.error('Error fetching chatbot response:', error);
      const errorReply = {
        text: "There was an error processing your query. Please try again later.",
        sender: 'bot'
      };
      setMessages(prevMessages => [...prevMessages, errorReply]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return; // Skip scrolling on initial render
    }

    if (latestMessageRef.current) {
      latestMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [messages]);

  return (
    <Container maxWidth="sm" 
        sx={{ 
        height: '600px',
        display: 'flex', 
        flexDirection: 'column' 
    }}>
      <Paper 
        elevation={3} 
        sx={{ 
          flex: 1, 
          display: 'flex', 
          flexDirection: 'column', 
          borderRadius: 2, 
          overflow: 'hidden' 
        }}
      >
        
        {/* Chat Title Area */}
        <Box 
          sx={{ 
            bgcolor: 'primary.main', 
            color: 'white', 
            p: 2, 
            display: 'flex', 
            alignItems: 'center' 
          }}
        >
          <BotAvatar sx={{ mr: 2 }} />
          <Typography variant="h6">Bot Steven</Typography>
        </Box>

        {/* Message List Area */}
        <List 
          sx={{ 
            flex: 1, 
            overflowY: 'auto', 
            p: 2 
          }}
        >
          {messages.map((msg, index) => (
            <ListItem 
              key={index}
              sx={{ 
                justifyContent: msg.sender === 'user' ? 'flex-end' : 'flex-start',
                mb: 1 
              }}
              ref={index === messages.length - 1 ? latestMessageRef : null} // Set ref to the latest message
            >
              <ListItemText
                primary={msg.text}
                primaryTypographyProps={{ 
                  variant: 'body2', // Use smaller text variant
                  fontSize: '1rem' // Adjust font size as needed
                }}
                sx={{
                  maxWidth: '70%',
                  bgcolor: msg.sender === 'user' ? 'primary.light' : 'grey.200',
                  color: msg.sender === 'user' ? 'white' : 'black',
                  p: 1.5,
                  borderRadius: 2,
                }}
              />
            </ListItem>
          ))}
        </List>

        {/* Input Area */}
        <Box 
          sx={{ 
            p: 2, 
            borderTop: '1px solid', 
            borderColor: 'divider', 
            display: 'flex' 
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Enter a query..."
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
            sx={{ mr: 2 }}
            disabled={loading}
          />
          <IconButton 
            color="primary" 
            onClick={handleSendMessage}
            sx={{ borderRadius: 2 }}
            disabled={loading}
          >
            <SendIcon />
          </IconButton>
        </Box>
      </Paper>
    </Container>
  );
};

export default ChatbotCard;
