import React, { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MuiCard from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import { SettingsContext } from '../../context/SettingsContext';
import UserService from '../../services/UserService';
import { useUser } from '../../context/UserContext'; // 引入 useUser
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';

const Card = styled(MuiCard)(({ theme, themeMode }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  padding: '32px',
  margin: 'auto',
  borderRadius: '8px',
  gap: '16px', // 默认 gap 为 16px
  boxShadow: themeMode === 'dark'
    ? '0px 4px 20px rgba(0, 0, 0, 0.5)'
    : '0px 4px 20px rgba(0, 0, 0, 0.2)',
  [theme.breakpoints.down('sm')]: {
    padding: '24px',
    gap: '8px', // sm 以下屏幕 gap 为 8px
  },
}));

export default function LoginForm({ onClose, onSignupClick }) {
  const { language, themeMode } = useContext(SettingsContext);
  const { userState, setUser } = useUser(); // 获取 userState

  // 定义语言文本
  const text = {
    en: {
      login: 'Log in',
      username: 'Username',
      usernamePlaceholder: 'username',
      password: 'Password',
      passwordPlaceholder: '••••••',
      rememberMe: 'Remember me',
      loginButton: 'Log In',
      cancelButton: 'Cancel',
      dividerText: 'or',
      noAccount: "Don't have an account?",
      signUp: 'Sign up',
      usernameError: 'Username is required',
      passwordError: 'Password is required',
      unexpectedError: 'An unexpected error occurred',
    },
    cn: {
      login: '登录',
      username: '用户名',
      usernamePlaceholder: '用户名',
      password: '密码',
      passwordPlaceholder: '••••••',
      rememberMe: '记住我',
      loginButton: '登录',
      cancelButton: '取消',
      dividerText: '或',
      noAccount: '还没有账号？',
      signUp: '注册',
      usernameError: '用户名是必填项',
      passwordError: '密码是必填项',
      unexpectedError: '发生了意外错误',
    },
  };

  const t = text[language] || text.en; // 默认为英文

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameError, setUsernameError] = useState(false);
  const [usernameErrorMessage, setUsernameErrorMessage] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setUsernameError(false);
    setPasswordError(false);
    setUsernameErrorMessage('');
    setPasswordErrorMessage('');
    setLoading(true); // Show spinner
  
    if (!username) {
      setUsernameError(true);
      setUsernameErrorMessage(t.usernameError);
      setLoading(false); // Hide spinner
      return;
    }
  
    if (!password) {
      setPasswordError(true);
      setPasswordErrorMessage(t.passwordError);
      setLoading(false); // Hide spinner
      return;
    }
  
    try {
      const userData = await UserService.login(username, password);
      // console.log(userData)
      setUser(userData);
      if (onClose) onClose();
    } catch (error) {
      console.error('Login failed:', error);
      setPasswordError(true);
      setPasswordErrorMessage(
        error.response?.data?.detail || t.unexpectedError
      );
    } finally {
      setLoading(false); // Hide spinner
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 2,
      }}
    >
      <Card
        component="form"
        onSubmit={handleSubmit}
        sx={{
          width: {
            xs: '90%',
            md: '100%',
          },
          maxWidth: '450px',
        }}
      >
        {/* 添加关闭按钮 */}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: {
              xs: 35, // 小屏幕 (xs) 的右侧间距
              md: 20, // 中等及以上屏幕 (md) 的右侧间距
            },
            top: {
              xs: 15, // 小屏幕 (xs) 的顶部间距
              md: 20, // 中等及以上屏幕 (md) 的顶部间距
            },
          }}
        >
          <CloseRoundedIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h4"
          align="center"
          sx={{
            fontWeight: 'bold',
            fontSize: {
              xs: '1.5rem',
              md: '1.8rem',
            },
            color: 'primary.main',
            letterSpacing: 0.8,
          }}
        >
          {t.login}
        </Typography>
        <FormControl>
          <FormLabel htmlFor="username">{t.username}</FormLabel>
          <TextField
            error={usernameError}
            helperText={usernameErrorMessage}
            id="username"
            type="text"
            name="username"
            placeholder={t.usernamePlaceholder}
            autoComplete="username"
            fullWidth
            variant="outlined"
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            sx={{
              backgroundColor: themeMode === 'dark' ? '#266798' : '#fff',
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="password">{t.password}</FormLabel>
          <TextField
            error={passwordError}
            helperText={passwordErrorMessage}
            id="password"
            type="password"
            name="password"
            placeholder={t.passwordPlaceholder}
            autoComplete="current-password"
            fullWidth
            variant="outlined"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{
              backgroundColor: themeMode === 'dark' ? '#266798' : '#fff',
            }}
          />
        </FormControl>
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {t.rememberMe}
              {loading && (
                <CircularProgress
                  size={20}
                  sx={{
                    marginLeft: 2, // Adds a small gap between the label and spinner
                  }}
                />
              )}
            </Box>
          }
        />

        <Button type="submit" fullWidth variant="contained" color="primary">
          {t.loginButton}
        </Button>
        {/* <Button
          onClick={(e) => {
            e.preventDefault();
            if (onClose) onClose();
          }}
          fullWidth
          variant="outlined"
          color="secondary"
        >
          {t.cancelButton}
        </Button> */}
        <Divider>{t.dividerText}</Divider>
        <Typography align="center" variant="body2">
          {t.noAccount}{' '}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              if (onSignupClick) onSignupClick();
            }}
          >
            {t.signUp}
          </a>
        </Typography>
      </Card>
    </Box>
  );
}

