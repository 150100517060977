import React, { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MuiCard from '@mui/material/Card';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { SettingsContext } from '../../context/SettingsContext';
import UserService from '../../services/UserService'; // 引入 userService
import { useUser } from '../../context/UserContext'
import { useMediaQuery, useTheme } from '@mui/material';

const Card = styled(MuiCard)(({ theme, themeMode }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: '30px',
  gap: '8px',
  margin: 'auto',
  maxWidth: '450px',
  borderRadius: '8px',
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.down('sm')]: {
    padding: '24px',
    gap: '8px', // sm 以下屏幕 gap 为 8px
  },
}));

export default function SignupForm({ onClose, onLoginClick }) {
  const { setUser } = useUser(); // 获取 userState
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const { language, themeMode } = useContext(SettingsContext);

  // 定义语言文本
  const text = {
    en: {
      signup: 'Sign up',
      username: 'Username',
      usernamePlaceholder: 'Enter your username',
      password: 'Password',
      confirmPassword: 'Confirm Password',
      inviteCode: 'Invite Code (If needed, email Steven)',
      terms: 'I agree to the terms and conditions.',
      signupButton: 'Sign Up',
      cancelButton: 'Cancel',
      dividerText: 'or',
      haveAccount: 'Already have an account?',
      login: 'Log in',
      usernameError: 'Username must be at least 3 characters long.',
      passwordError: 'Password must be at least 6 characters long.',
      confirmPasswordError: 'Passwords do not match.',
      inviteCodeError: 'Invite code is required.',
      termsError: 'You must agree to the terms and conditions.',
      apiError: 'An unexpected error occurred.',
    },
    cn: {
      signup: '注册',
      username: '用户名',
      usernamePlaceholder: '请输入用户名',
      password: '密码',
      confirmPassword: '确认密码',
      inviteCode: '邀请码（找骆熙要）',
      terms: '我同意本网站的条款和协议。',
      signupButton: '注册',
      cancelButton: '取消',
      dividerText: '或',
      haveAccount: '已经有账号？',
      login: '登录',
      usernameError: '用户名至少需要3个字符。',
      passwordError: '密码至少需要6个字符。',
      confirmPasswordError: '两次输入的密码不一致。',
      inviteCodeError: '邀请码是必填项。',
      termsError: '您必须同意条款和协议。',
      apiError: '发生了意外错误。',
    },
  };


  const t = text[language] || text.en;

  const [usernameError, setUsernameError] = useState(false);
  const [usernameErrorMessage, setUsernameErrorMessage] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState('');
  const [inviteCodeError, setInviteCodeError] = useState(false);
  const [inviteCodeErrorMessage, setInviteCodeErrorMessage] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [apiError, setApiError] = useState('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // success, error, warning, info


  const validateInputs = () => {
    const username = document.getElementById('username');
    const password = document.getElementById('password');
    const confirmPassword = document.getElementById('confirmPassword');
    const inviteCode = document.getElementById('inviteCode');

    let isValid = true;

    if (!username.value || username.value.length < 3) {
      setUsernameError(true);
      setUsernameErrorMessage(t.usernameError);
      isValid = false;
    } else {
      setUsernameError(false);
      setUsernameErrorMessage('');
    }

    if (!password.value || password.value.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage(t.passwordError);
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    if (confirmPassword.value !== password.value) {
      setConfirmPasswordError(true);
      setConfirmPasswordErrorMessage(t.confirmPasswordError);
      isValid = false;
    } else {
      setConfirmPasswordError(false);
      setConfirmPasswordErrorMessage('');
    }

    if (!inviteCode.value) {
      setInviteCodeError(true);
      setInviteCodeErrorMessage(t.inviteCodeError);
      isValid = false;
    } else {
      setInviteCodeError(false);
      setInviteCodeErrorMessage('');
    }

    if (!termsAccepted) {
      setTermsError(true);
      isValid = false;
    } else {
      setTermsError(false);
    }

    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateInputs()) return;
  
    const data = new FormData(event.currentTarget);
    const username = data.get('username');
    const password = data.get('password');
    const inviteCode = data.get('inviteCode');
  
    try {
      const response = await UserService.register(username, password, inviteCode);
      if (response.status === 201) {
        // 显示 Snackbar 消息
        setSnackbarMessage(t.signupButton + ' ' + t.success);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      
        // 自动登录
        const loginResponse = await UserService.login(username, password);
        if (loginResponse.status === 200) {
          setUser(userData);
          if (onClose) onClose();
        }
      }
      
    } catch (error) {
      if (error.response && error.response.data) {
        const errors = error.response.data;
  
        if (errors.username) {
          setUsernameError(true);
          setUsernameErrorMessage(errors.username[0]);
        } else {
          setUsernameError(false);
          setUsernameErrorMessage('');
        }
  
        if (errors.password) {
          setPasswordError(true);
          setPasswordErrorMessage(errors.password[0]);
        } else {
          setPasswordError(false);
          setPasswordErrorMessage('');
        }
  
        if (errors.invite_code) {
          setInviteCodeError(true);
          setInviteCodeErrorMessage(errors.invite_code[0]);
        } else {
          setInviteCodeError(false);
          setInviteCodeErrorMessage('');
        }
      } else {
        setApiError(t.apiError);
        console.error(t.signup + ' ' + t.error + ':', error);
      }
    }
  };
  

  return (
    <Card component="form" onSubmit={handleSubmit}>

      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: {
            xs: 6, // 小屏幕 (xs) 的右侧间距
            md: 6, // 中等及以上屏幕 (md) 的右侧间距
          },
          top: {
            xs: 6, // 小屏幕 (xs) 的顶部间距
            md: 6, // 中等及以上屏幕 (md) 的顶部间距
          },
        }}
      >
        <CloseRoundedIcon />
      </IconButton>
      <Typography
        component="h1"
        variant="h4"
        align="center"
        sx={{
          fontWeight: 'bold',
          fontSize: { md: '1.8rem', xs: '1.8rem' },
          color: 'primary.main',
          letterSpacing: 0.8,
        }}
      >
        {t.signup}
      </Typography>

      {apiError && (
        <Typography color="error" align="center">
          {apiError}
        </Typography>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <FormControl>
          <FormLabel htmlFor="username">{t.username}</FormLabel>
          <TextField
            error={usernameError}
            helperText={usernameErrorMessage}
            autoComplete="username"
            name="username"
            required
            fullWidth
            id="username"
            placeholder={t.usernamePlaceholder}
            variant="outlined"
            size={isMediumScreen ? 'medium' : 'small'}
            sx={{
              backgroundColor: themeMode === 'dark' ? '#266798' : '#fff',
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="password">{t.password}</FormLabel>
          <TextField
            error={passwordError}
            helperText={passwordErrorMessage}
            required
            fullWidth
            name="password"
            type="password"
            id="password"
            autoComplete="new-password"
            variant="outlined"
            size={isMediumScreen ? 'medium' : 'small'}
            sx={{
              backgroundColor: themeMode === 'dark' ? '#266798' : '#fff',
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="confirmPassword">{t.confirmPassword}</FormLabel>
          <TextField
            error={confirmPasswordError}
            helperText={confirmPasswordErrorMessage}
            required
            fullWidth
            name="confirmPassword"
            type="password"
            id="confirmPassword"
            autoComplete="new-password"
            variant="outlined"
            size={isMediumScreen ? 'medium' : 'small'}
            sx={{
              backgroundColor: themeMode === 'dark' ? '#266798' : '#fff',
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="inviteCode">{t.inviteCode}</FormLabel>
          <TextField
            error={inviteCodeError}
            helperText={inviteCodeErrorMessage}
            required
            fullWidth
            name="inviteCode"
            id="inviteCode"
            variant="outlined"
            size={isMediumScreen ? 'medium' : 'small'}
            sx={{
              backgroundColor: themeMode === 'dark' ? '#266798' : '#fff',
            }}
          />
        </FormControl>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                required
                color="primary"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
              />
            }
            label={t.terms}
          />
          {termsError && (
            <Typography color="error" variant="body2" sx={{ mt: 0.5 }}>
              {t.termsError}
            </Typography>
          )}
        </FormControl>

        <Button type="submit" fullWidth variant="contained">
          {t.signupButton}
        </Button>
        {/* <Button
          onClick={(e) => {
            e.preventDefault();
            if (onClose) onClose();
          }}
          fullWidth
          variant="outlined"
          color="secondary"
        >
          {t.cancelButton}
        </Button> */}
      </Box>
      <Divider>
        <Typography sx={{ color: 'text.secondary' }}>{t.dividerText}</Typography>
      </Divider>
      <Box>
        <Typography align="center" variant="body2">
          {t.haveAccount}{' '}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              if (onLoginClick) onLoginClick();
            }}
          >
            {t.login}
          </a>
        </Typography>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Card>
  );
}
