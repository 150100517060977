import React, { useEffect, useRef, useContext } from 'react';
import { createChart } from 'lightweight-charts';
import { SettingsContext } from '../../context/SettingsContext';

const SimpleStockChart = ({ data }) => {
  const chartContainerRef = useRef(null);
  const legendRef = useRef(null);
  const { themeMode, language } = useContext(SettingsContext);

  useEffect(() => {
    if (!data || !data.history || data.history.length === 0) {
      console.error('Invalid data format');
      return;
    }

    const isDarkMode = themeMode === 'dark';
    const isChineseLanguage = language === 'cn';

    const chartOptions = {
      width: chartContainerRef.current.clientWidth,
      layout: {
        background: { color: isDarkMode ? '#1a1a1a' : '#f5f5f5', type: 'solid' },
        textColor: isDarkMode ? '#d1d4dc' : '#000000',
      },
      grid: {
        vertLines: { color: isDarkMode ? '#2B2B43' : '#E0E0E0' },
        horzLines: { color: isDarkMode ? '#2B2B43' : '#E0E0E0' },
      },
      crosshair: {
        mode: 1,
        vertLine: {
          color: '#758696',
          width: 1,
          style: 1,
          labelBackgroundColor: isDarkMode ? '#1a1a1a' : '#FFFFFF',
        },
        horzLine: {
          color: '#758696',
          width: 1,
          style: 1,
          labelBackgroundColor: isDarkMode ? '#1a1a1a' : '#FFFFFF',
        },
      },
      timeScale: {
        borderColor: isDarkMode ? '#2B2B43' : '#E0E0E0',
      },
      rightPriceScale: {
        borderColor: isDarkMode ? '#2B2B43' : '#E0E0E0',
        scaleMargins: {
          top: 0.1,
          bottom: 0.2,
        },
      },
      watermark: {
        visible: false,
      },
      credits: {
        enabled: false,
      },
    };

    const chart = createChart(chartContainerRef.current, chartOptions);

    const mainPriceScale = chart.priceScale('right');
    const volumePriceScale = chart.priceScale('left');
    volumePriceScale.applyOptions({
      scaleMargins: {
        top: 0.8,
        bottom: 0,
      },
      visible: false,
    });

    // 在中文环境，红涨绿跌；英文环境，绿涨红跌
    const candleSeries = chart.addCandlestickSeries({
      upColor: isChineseLanguage ? '#ef5350' : '#26a69a',
      downColor: isChineseLanguage ? '#26a69a' : '#ef5350',
      borderVisible: false,
      wickUpColor: isChineseLanguage ? '#ef5350' : '#26a69a',
      wickDownColor: isChineseLanguage ? '#26a69a' : '#ef5350',
    });

    const volumeSeries = chart.addHistogramSeries({
      color: '#26a69a50',
      priceFormat: {
        type: 'volume',
      },
      priceScaleId: 'left',
    });

    const chartData = data.history.map((item) => ({
      time: new Date(item.Date).getTime() / 1000,
      open: item.Open,
      high: item.High,
      low: item.Low,
      close: item.Close,
    }));

    const volumeData = data.history.map((item) => ({
      time: new Date(item.Date).getTime() / 1000,
      value: item.Volume,
      color:
        item.Close >= item.Open
          ? isChineseLanguage
            ? '#ef535050'
            : '#26a69a50'
          : isChineseLanguage
          ? '#26a69a50'
          : '#ef535050',
    }));

    candleSeries.setData(chartData);
    volumeSeries.setData(volumeData);

    // 创建并样式化图例容器
    const legend = document.createElement('div');
    legend.style.position = 'absolute';
    legend.style.left = '1px';
    legend.style.top = '1px';
    legend.style.zIndex = '1';
    legend.style.fontSize = '12px';
    legend.style.fontFamily = 'sans-serif';
    legend.style.color = isDarkMode ? '#d1d4dc' : '#131722';
    legend.style.display = 'flex';
    legend.style.gap = '8px';
    legend.style.alignItems = 'center';
    legendRef.current = legend;
    chartContainerRef.current.appendChild(legend);

    // 订阅 crosshair 移动事件
    chart.subscribeCrosshairMove((param) => {
      if (!param.time || !param.point) {
        legend.style.display = 'none';
        return;
      }

      const candleData = param.seriesData.get(candleSeries);
      const volData = param.seriesData.get(volumeSeries);

      if (!candleData || !volData) {
        legend.style.display = 'none';
        return;
      }

      legend.style.display = 'flex';

      // 根据语言，对日期做不同格式化
      const date = new Date(param.time * 1000);

      let formattedDate;
      if (isChineseLanguage) {
        // yyyy/mm/dd
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        formattedDate = `${year}/${month}/${day}`;
      } else {
        // dd/Mon/yyyy (Mon 为英文缩写)
        const day = date.getDate();
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        formattedDate = `${day}/${month}/${year}`;
      }

      const formatPrice = (price) => price.toFixed(2);
      const formatVolume = (volume) => {
        if (volume >= 1000000) {
          return (volume / 1000000).toFixed(2) + 'M';
        } else if (volume >= 1000) {
          return (volume / 1000).toFixed(2) + 'K';
        }
        return volume.toFixed(0);
      };

      // 根据语言切换标签
      const OLabel = isChineseLanguage ? '开' : 'O';
      const HLabel = isChineseLanguage ? '高' : 'H';
      const LLabel = isChineseLanguage ? '低' : 'L';
      const CLabel = isChineseLanguage ? '收' : 'C';
      const VLabel = isChineseLanguage ? '量' : 'V';

      legend.innerHTML = `
        <span>${formattedDate}</span>
        <span>${OLabel}: ${formatPrice(candleData.open)}</span>
        <span>${HLabel}: ${formatPrice(candleData.high)}</span>
        <span>${LLabel}: ${formatPrice(candleData.low)}</span>
        <span>${CLabel}: ${formatPrice(candleData.close)}</span>
        <span>${VLabel}: ${formatVolume(volData.value)}</span>
      `;
    });

    // 隐藏 TradingView logo
    const style = document.createElement('style');
    style.textContent = `
      #tv-attr-logo {
        display: none !important;
      }
    `;
    document.head.appendChild(style);

    // 处理图表自适应
    const handleResize = () => {
      chart.applyOptions({
        width: chartContainerRef.current.clientWidth,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.head.removeChild(style);
      if (legendRef.current && chartContainerRef.current) {
        chartContainerRef.current.removeChild(legendRef.current);
      }
      chart.remove();
    };
  }, [data, themeMode, language]);

  return (
    <div
      ref={chartContainerRef}
      style={{
        width: '100%',
        height: '250px',
        backgroundColor: themeMode === 'dark' ? '#1a1a1a' : '#FFFFFF',
        position: 'relative',
      }}
    />
  );
};

export default SimpleStockChart;
