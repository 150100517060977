import React, { useContext } from 'react';
import { Box, Fab, Container } from '@mui/material';
import CustomAppBar from '../../components/shared-components/AppBar';
import { SettingsContext } from '../../context/SettingsContext'; // 引入 SettingsContext
import BlogCard from '../../components/blog-components/BlogCard';
import { useUser } from '../../context/UserContext'; // 引入 UserContext
import AddIcon from '@mui/icons-material/Add'; // 引入 AddIcon
import { useNavigate } from 'react-router-dom'; // 引入 useNavigate
import Footer from '../../components/shared-components/Footer';

const Blogs = () => {
  const { language, themeMode, toggleLanguage, toggleTheme } = useContext(SettingsContext); // 获取当前语言和主题
  const { userState } = useUser(); // 从 UserContext 获取用户状态
  const navigate = useNavigate(); // 初始化 navigate

  const handleAddBlog = () => {
    navigate('/addblog'); // 跳转到 AddBlogPage
  };


  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
        <CustomAppBar />
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: "100px",
          }}
        >
          <BlogCard />
        </Container>

        {/* 右下角固定按钮，只有 isStaff 为 true 时显示 */}
        {userState.is_staff && (
          <Fab
            color="primary"
            aria-label="add"
            onClick={handleAddBlog} // 绑定点击事件
            sx={{
              position: 'fixed', // 页面固定位置
              bottom: '50px', // 距离页面底部
              right: 'calc((100% - 1200px) / 2 + 20px)', // 居中区域 + 距离右侧 20px
              '@media (max-width: 1280px)': {
                right: '30px', // 小屏幕直接靠右
              },
            }}
          >
            <AddIcon />
          </Fab>
        )}
      </Box>

      {/* 下部 Footer */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'background.default',
          borderTop: (theme) => ({
            xs: 'none', // 在 xs 屏幕无边框
            md: `1px solid ${theme.palette.divider}`, // 在 md 屏幕及以上显示边框
          }),
          maxWidth: (theme) => theme.breakpoints.values.lg, // 限制最大宽度为 lg
          width: '100%', // 确保宽度是 100% 的父元素宽度
          mx: 'auto', // 水平居中
        }}
      >
        <Footer />
      </Box>
    </Box>
  );
};

export default Blogs;