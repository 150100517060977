import React, { useEffect, useState, useContext } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { SettingsContext } from '../../context/SettingsContext'; // 引入 SettingsContext
import axios from "axios";
import BASE_URL from "../../config"; // 引入后端地址配置

// Markdown
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import remarkGfm from "remark-gfm"; 
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css";
import rehypeRaw from "rehype-raw"; 
import rehypeHighlight from "rehype-highlight"; 

// 导入code样式字符串
import vsdark from '../../styles/vsdark';
import vslight from '../../styles/vslight';

// 目录
import remarkSlug from "remark-slug"; 
import rehypeAutolinkHeadings from "rehype-autolink-headings";


const MarkdownViewer = ({ filePath, target=null }) => {
  const [markdownContent, setMarkdownContent] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // 用于存放错误信息
  const { themeMode , language } = useContext(SettingsContext); // 获取当前语言和主题

  useEffect(() => {
    const loadMarkdown = async () => {
      try {
        // 注意：加载前先清空可能的 errorMessage，以免多次切换时一直保留错误提示
        setErrorMessage("");

        if (target === "note_md") {
          let sanitizedFilePath = filePath;
          if (filePath.startsWith("/")) {
            sanitizedFilePath = filePath.substring(1);
          }
          const mdResponse = await axios.get(`${BASE_URL}/${sanitizedFilePath}`);
          setMarkdownContent(mdResponse.data);

        } else if (target === "note_readme") {
          if (filePath === "cn") {
            const mdResponse = await axios.get(`${BASE_URL}/get_note_readme/cn/`);
            setMarkdownContent(mdResponse.data);
          } else if (filePath === "en") {
            const mdResponse = await axios.get(`${BASE_URL}/get_note_readme/en/`);
            setMarkdownContent(mdResponse.data);
          }
        } else {
          // 普通路径
          const response = await axios.get(filePath);
          setMarkdownContent(response.data);
        }
      } catch (error) {
        console.error(error);
        if (language === "cn") {
          setErrorMessage("404 \n 服务器访问失败 \n 无法加载Markdown 文件");
        } else {
          setErrorMessage("404 \n Interel Server Error. \n Fail to load markdown file.");
        }
      }
    };

    if (filePath) {
      loadMarkdown();
    }
  }, [filePath, target, language]);

  // 动态切换代码高亮的主题
  useEffect(() => {
    const styleTagId = 'code-theme-styles';
    let styleTag = document.getElementById(styleTagId);

    if (!styleTag) {
      styleTag = document.createElement('style');
      styleTag.type = 'text/css';
      styleTag.id = styleTagId;
      document.head.appendChild(styleTag);
    }

    if (themeMode === 'dark') {
      styleTag.innerHTML = vsdark;
    } else {
      styleTag.innerHTML = vslight;
    }
  }, [themeMode]);
  
  // 根据有无 errorMessage、markdownContent 来决定最终渲染
  if (errorMessage) {
    // 如果有错误信息，优先渲染错误提示
    return (
      <Typography variant="h6" color="error" align="center" mt={4}  style={{ whiteSpace: "pre-line" }}>
        {errorMessage}
      </Typography>
    );
  }

  return (
    <Box>
      <Box>
        {markdownContent ? (
          <ReactMarkdown
            children={markdownContent}
            urlTransform={(url, children, title) => {
              if (title.tagName === 'img') {
                return url.startsWith("http") || url.startsWith("https")
                  ? url
                  : `${BASE_URL}/${url}`;
              }
              return url;
            }}
            remarkPlugins={[remarkMath, remarkGfm, remarkSlug]}
            rehypePlugins={[
              rehypeKatex,
              rehypeRaw,
              rehypeHighlight,
              rehypeAutolinkHeadings,
            ]}
            components={{
              h1: ({ node, ...props }) => (
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{
                    fontWeight: 600,
                    lineHeight: 1.5,
                    marginBottom: 2,
                    pb: 2,
                    borderBottom: "2px solid",
                    borderColor: themeMode === "dark" ? "#888" : "#aaa",
                  }}
                  {...props}
                />
              ),
              h2: ({ node, ...props }) => (
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    fontWeight: 600,
                    lineHeight: 1.5,
                    pb: 1.5,
                    marginTop: 3,
                    marginBottom: 1.5,
                    borderBottom: "2px solid",
                    borderColor: themeMode === "dark" ? "#555" : "#ccc",
                  }}
                  {...props}
                />
              ),
              h3: ({ node, ...props }) => (
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{ fontWeight: 600, lineHeight: 1.4, marginBottom: 1.2 }}
                  {...props}
                />
              ),
              p: ({ node, ...props }) => (
                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{
                    fontSize: "0.9rem",
                    lineHeight: 1.6,
                    marginBottom: 1.5,
                  }}
                  {...props}
                />
              ),
              li: ({ node, ...props }) => (
                <Typography
                  component="li"
                  variant="body2"
                  gutterBottom
                  sx={{
                    fontSize: "0.9rem",
                    lineHeight: 1.6,
                    marginBottom: 0.5,
                  }}
                  {...props}
                />
              ),
              a: ({ node, ...props }) => {
                const isExternalLink =
                  props.href.startsWith("http://") ||
                  props.href.startsWith("https://");
                return (
                  <Typography
                    component="a"
                    variant="body2"
                    sx={{
                      color: themeMode === "dark" ? "#64b5f6" : "#13479c",
                      textDecoration: "none",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                    target={isExternalLink ? "_blank" : "_self"}
                    rel={isExternalLink ? "noopener noreferrer" : undefined}
                    {...props}
                  />
                );
              },
              img: ({ node, ...props }) => (
                <Box
                  component="img"
                  sx={{
                    maxWidth: { xs: "100%", md: "85%" },
                    height: "auto",
                    display: "block",
                    margin: "0 auto",
                  }}
                  {...props}
                />
              ),
              table: ({ node, ...props }) => (
                <Box
                  component="table"
                  sx={{
                    margin: "0 auto",
                    borderCollapse: "collapse",
                    maxWidth: { xs: "100%", md: "85%" },
                    width: "100%",
                    overflowX: "auto",
                  }}
                  {...props}
                />
              ),
              thead: ({ node, ...props }) => (
                <Box
                  component="thead"
                  sx={{
                    borderBottom: "2px solid",
                    borderColor: themeMode === "dark" ? "#555" : "#ccc",
                  }}
                  {...props}
                />
              ),
              tr: ({ node, ...props }) => (
                <Box
                  component="tr"
                  sx={{
                    borderBottom: "1px solid",
                    borderColor: themeMode === "dark" ? "#444" : "#ddd",
                  }}
                  {...props}
                />
              ),
              th: ({ node, ...props }) => (
                <Box
                  component="th"
                  sx={{
                    padding: "8px",
                    textAlign: "center",
                    fontWeight: "bold",
                    borderBottom: "2px solid",
                    borderColor: themeMode === "dark" ? "#555" : "#ccc",
                  }}
                  {...props}
                />
              ),
              td: ({ node, ...props }) => (
                <Box
                  component="td"
                  sx={{
                    padding: "8px",
                    textAlign: "center",
                    borderBottom: "1px solid",
                    borderColor: themeMode === "dark" ? "#444" : "#ddd",
                  }}
                  {...props}
                />
              ),
              code: ({ node, inline, ...props }) => (
                <Box
                  component="code"
                  sx={{
                    backgroundColor:
                      themeMode === "dark" ? "#353535" : "#E7E7E7",
                    fontFamily:
                      "'Menlo', 'Consolas', 'Courier New', monospace",
                  }}
                  {...props}
                />
              ),
              pre: ({ node, ...props }) => (
                <Box
                  component="pre"
                  sx={{
                    borderRadius: "5px",
                    overflowX: "auto", 
                    maxWidth: "100%",
                    marginBottom: "1rem",
                    fontSize: "0.9rem",
                    boxShadow:
                      "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06)",
                  }}
                  {...props}
                />
              ),
            }}
          />
        ) : (
          // 如果既没有出错，也没有拿到 markdownContent，就显示“正在加载”或 “Loading...”
          <Typography variant="h6" align="center" mt={3.5}>
            {language === "cn" ? "正在加载内容..." : "Loading content..."}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default MarkdownViewer;
