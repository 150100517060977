import React, { useContext, useEffect, useState } from 'react';
import { Container, Card, CardContent, Typography, Grid, Box } from '@mui/material';
import BlogService from '../../services/BlogService';
import { useNavigate } from 'react-router-dom';
import { SettingsContext } from '../../context/SettingsContext';

const BlogCard = ({ sx }) => {
    const { language, themeMode } = useContext(SettingsContext);
    const [blogs, setBlogs] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true); // 新增加载状态
    const [error, setError] = useState(null);     // 新增错误状态
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMetadata = async () => {
            try {
                setLoading(true); // 开始加载
                const metadata = await BlogService.getMeta();
                setBlogs(metadata.data);
                setTotal(metadata.total);
                setLoading(false); // 加载成功
            } catch (error) {
                console.error('Error fetching metadata:', error);
                setError(language === 'cn' ? '404 \n 服务器访问失败 \n 无法加载博客' : '404 \n Internal Server Error \n Fail to load blogs');
                setLoading(false); // 加载失败
            }
        };
        fetchMetadata();
    }, [language]);

    const handleCardClick = (id) => {
        navigate(`/blog/${id}`);
    };

    return (
        <Container maxWidth="md" sx={{ ...sx }}>
            {loading ? ( // 加载状态
                <Typography variant="h6" align="center" mt={3.5}>
                    {language === 'cn' ? '正在加载内容...' : 'Loading content...'}
                </Typography>
            ) : error ? ( // 加载失败状态
                <Typography variant="h6" color="error" align="center" mt={3.5} style={{ whiteSpace: "pre-line" }}>
                    {error}
                </Typography>
            ) : (
                <Grid container spacing={2} direction="column">
                    {blogs.map((blog) => (
                        <Grid item key={blog.id}>
                            <Card
                                sx={{
                                    background: 'transparent',
                                    borderTop: 'none',
                                    borderLeft: 'none',
                                    borderRight: 'none',
                                    borderBottom: `1px solid ${themeMode === 'light' ? '#ccc' : '#555'}`,
                                    borderRadius: 0,
                                    boxShadow: 'none',
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleCardClick(blog.id)}
                            >
                                <CardContent>
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        gutterBottom
                                        sx={{ fontFamily: 'Georgia, serif', fontWeight: 'bold' }}
                                    >
                                        {language === 'cn' ? blog.cn_title : blog.en_title}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        color="text.primary"
                                        sx={{ fontFamily: 'Georgia, serif' }}
                                    >
                                        {language === 'cn' ? blog.cn_summary : blog.en_summary}
                                    </Typography>
                                    <Box display="flex" justifyContent="flex-end" mt={2} sx={{ fontFamily: 'Georgia, serif' }}>
                                        <Typography variant="body1" color="text.secondary">
                                            {language === 'cn'
                                                ? new Date(blog.publish_date).toLocaleDateString('zh-CN')
                                                : new Date(blog.publish_date).toLocaleDateString('en-GB')}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Container>
    );
};

export default BlogCard;