// ./context/UserContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import UserService from '../services/UserService';
import Cookies from 'js-cookie';

// 创建上下文
const UserContext = createContext();

// 提供者组件
export const UserProvider = ({ children }) => {
  const [userState, setUserState] = useState({
    access: null,
    refresh: null,
    user_id: 0,
    username: "",
    is_superuser: false,
    is_staff: false,
    is_user: false,
    cookies: {},
  });
  
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      const userData = await UserService.initializeFromCookies();
      setUser(userData);
      setInitialized(true);
    };
    initialize();
  }, []);

  // useEffect(() => {
  //   console.log("Updated userState:", userState);
  // }, [userState]);

  // 设置用户信息
  const setUser = (data) => {
    if (!data) return;
    
    const newState = {
      access: data.access,
      refresh: data.refresh,
      user_id: data.user_id,
      username: data.username,
      is_superuser: data.is_superuser,
      is_staff: data.is_staff,
      is_user: data.is_user,
      cookies: {
        refresh: Cookies.get('refresh'),
        userId: Cookies.get('user_id'),
        isUser: Cookies.get('is_user'),
      },
    };

    // 更新 state
    setUserState(newState);

    // 保存到 cookies
    Cookies.set('refresh', data.refresh, { expires: 7 });
    Cookies.set('user_id', data.user_id, { expires: 7 });
    Cookies.set('is_user', data.is_user, { expires: 7 });
  };

  // 获取用户信息
  const getUser = () => {
    return userState;
  };

  return (
    <UserContext.Provider value={{ userState, setUser, getUser, initialized }}>
      {children}
    </UserContext.Provider>
  );
};

// 自定义 Hook 简化使用
export const useUser = () => useContext(UserContext);
