import React, { useContext } from 'react';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import { SettingsContext } from '../../context/SettingsContext';
import CustomAppBar from '../../components/shared-components/AppBar';
import ProfileCard from "../../components/shared-components/ProfileCard";
import Footer from '../../components/shared-components/Footer';
import HomeCard from '../../components/legacy-components/HomeCard';
import BlogCard from '../../components/blog-components/BlogCard';
import ChatbotCard from '../../components/chatbot-components/ChatbotCard';
import MarkdownViewer from "../../components/shared-components/MarkdownViewer";

const Home = () => {
  const { language, themeMode, toggleLanguage, toggleTheme } = useContext(SettingsContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const intro_filePath = language === "en" ? "md/en/luoxisteven_en.md" : "md/cn/luoxisteven_cn.md";
  const about_filePath = language === "en" ? "md/en/about_website_en.md" : "md/cn/about_website_cn.md";

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CustomAppBar />

      <Box
        sx={{
          marginTop: isMobile ? '120px' : '64px',
          minHeight: 'calc(100vh - 64px)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Container
          maxWidth={isMobile ? 'sm' : 'lg'}
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            minHeight: 'calc(100vh - 64px)',
          }}
        >
          <Box
            sx={{
              flex: isMobile ? 'none' : '1 1 20%',
              width: isMobile ? '100%' : 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ProfileCard />
          </Box>

          <Box
            sx={{
              flex: isMobile ? 'none' : '1 1 80%',
              width: isMobile ? '100%' : 'auto',
              display: 'flex',
              overflow: 'auto',
              alignItems: 'center',
            }}
          >
            <MarkdownViewer filePath={intro_filePath} />
          </Box>
        </Container>

        <Container
          maxWidth="lg"
          sx={{
            minHeight: '1px',
            borderTop: isMobile
              ? 'none'
              : `1px solid ${themeMode === 'light' ? '#ccc' : '#555'}`,
          }}
        />

        {/* 修改此处的 Box 以支持移动端和桌面端的布局 */}
        <Box
          sx={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row', // 动态设置 flexDirection
            maxWidth: isMobile ? 'sm' : 'lg',
            margin: '0 auto', // X轴居中
          }}
        >
          <Container
            sx={{
              flex: isMobile ? 'none' : '1 1 65%', // 左侧宽度为 65%
              width: isMobile ? '100%' : '65%',
              display: 'flex',
              overflow: 'auto',
              alignItems: 'center',
            }}
          >
            <MarkdownViewer filePath={about_filePath} />
          </Container>

          {/* 修改 ChatbotCard 的 Box 以在移动端和桌面端都显示 */}
          <Box
            sx={{
              flex: isMobile ? 'none' : '1 1 35%',
              width: isMobile ? '100%' : '35%', // 在移动端占满宽度
              display: 'flex', // 始终显示
              alignItems: 'center',
              marginTop: isMobile ? 2 : 0, // 在移动端增加顶部间距
              marginBottom: isMobile ? 2: 0,
            }}
          >
            <ChatbotCard />
          </Box>
        </Box>
      </Box>

      {/* 下部 Footer */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'background.default',
          borderTop: (theme) => ({
            xs: 'none', // 在 xs 屏幕无边框
            md: `1px solid ${theme.palette.divider}`, // 在 md 屏幕及以上显示边框
          }),
          maxWidth: (theme) => theme.breakpoints.values.lg, // 限制最大宽度为 lg
          width: '100%', // 确保宽度是 100% 的父元素宽度
          mx: 'auto', // 水平居中
        }}
      >
        <Footer />
      </Box>

    </Box>
  );
};

export default Home;
