import api from './api'; // 引入共享的 axios 实例

const FinanceService = {
    get_stock_history_data: async (ticker) => {
        try {
            const response = await api.get(`/get_stock_history_data/${ticker}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching stock history data:', error);
            throw error;
        }
    },
};

export default FinanceService;
