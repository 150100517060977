// ./services/userService.js
import axios from 'axios';
import Cookies from 'js-cookie';
import api from './api'; // 引入共享的 axios 实例

// Base64 解码函数
const base64Decode = (str) => {
  try {
    return JSON.parse(atob(str));
  } catch (e) {
    console.error("Failed to decode base64:", e);
    return null;
  }
};

const UserService = {
  // 注册新用户
  register: async (username, password, inviteCode) => {
    const data = { username, password, invite_code: inviteCode };
    const response = await api.post('/register/', data);
    return response.data;
  },

  // 获取用户列表，需要 accessToken
  getUsers: async (accessToken) => {
    const response = await api.get('/users/', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  },

  // 获取用户详情，需要 accessToken
  getUserDetail: async (userId, accessToken) => {
    const response = await api.get(`/users/${userId}/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  },

  // 登录
  login: async (username, password) => {
    const data = { username, password };
    const response = await api.post('/login/', data);
    return response.data; // 返回用户数据给调用方
  },

  // 刷新 Token
  refreshToken: async (refreshToken) => {
    try {
      const data = { refresh: refreshToken };
      const response = await api.post('/token/refresh/', data);
      if (response.status === 200) {
        return response.data; // 返回用户数据给调用方
      } else {
        console.error("Failed to refresh token. Status:", response.status);
        return null;
      }
    } catch (error) {
      console.error("Error while refreshing token:", error);
      return null;
    }
  },

  // 生成邀请码，需要 accessToken
  generateInviteCode: async (accessToken) => {
    const response = await api.post('/generate-invite-code/', {}, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  },

  // 获取邀请码列表，需要 accessToken
  getInviteCodes: async (accessToken) => {
    const response = await api.get('/invite-codes/', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  },

  // 获取匿名用户的 JWT
  getNonuserJWT: async () => {
    try {
      const response = await api.get('/nonuser/');
      if (response.status === 200) {
        return response.data; // 返回匿名用户数据
      } else {
        console.error("Failed to initialize app. Status:", response.status);
        return null;
      }
    } catch (error) {
      console.error("Error while initializing app:", error);
      return null;
    }
  },

  // 解码 JWT，需要 accessToken
  decodeJWT: async (accessToken) => {
    const response = await api.post('/decode-jwt/', {}, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  },

  // 从 Cookies 初始化用户状态
  // 需要在外部接收数据后由调用方决定如何使用返回的 data
  initializeFromCookies: async () => {
    const refresh = Cookies.get('refresh');
    const user_id = Cookies.get('user_id');
    
    // 检查是否存在 Refresh Token
    if (!refresh) {
      console.error("No refreshToken found in cookies.");
      const userData = await UserService.getNonuserJWT();
      return userData; // 返回匿名用户数据或null
    }

    if (user_id==0){
      const userData = await UserService.getNonuserJWT();
      return userData; // 返回匿名用户数据或null
    }

    // 检查 Refresh Token 格式
    const jwtParts = refresh.split('.');
    if (jwtParts.length !== 3) {
      console.error("Invalid JWT format.");
      const userData = await UserService.getNonuserJWT();
      return userData;
    }

    // 解析 JWT Payload
    const payload = base64Decode(jwtParts[1]);
    if (!payload) {
      console.error("Failed to decode JWT payload.");
      const userData = await UserService.getNonuserJWT();
      return userData;
    }

    // 检查 Token 是否过期
    const currentTime = Math.floor(Date.now() / 1000);
    if (payload.exp && currentTime >= payload.exp) {
      console.log("Refresh token has expired. Fetching non-user JWT...");
      const userData = await UserService.getNonuserJWT();
      return userData;
    } else {
      console.log("Refresh token is valid. Refreshing token...");
      const refreshedData = await UserService.refreshToken(refresh);
      return refreshedData;
    }
  },
};

export default UserService;
