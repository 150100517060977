import React from 'react';
import { Paper } from "@mui/material";
import { Editor } from "react-draft-wysiwyg";
// import "../../styles/react-wysiwyg-light.css";
import styles from "../../styles/Draft-js-wysiwyg.module.css";

const DraftJsWysiwyg = ({ 
  editorState, 
  onEditorStateChange, 
  placeholder = "",
  readOnly = false 
}) => {
  return (
    <Paper elevation={1} sx={{ }}>
      <Editor
        editorState={editorState}
        toolbarClassName={styles.toolbarClass}
        wrapperClassName={styles.wrapperClass}
        editorClassName={styles.editorClass}
        onEditorStateChange={onEditorStateChange}
        placeholder={placeholder}
        readOnly={readOnly}
        mention={{
          separator: " ",
          trigger: "@",
        }}
      />
    </Paper>
  );
};

export default DraftJsWysiwyg;