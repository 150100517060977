import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom'; // 从 react-router-dom 导入 useLocation
import { Box } from '@mui/material';
import CustomAppBar from '../../components/shared-components/AppBar';
import { SettingsContext } from '../../context/SettingsContext'; // 引入 SettingsContext
import Footer from '../../components/shared-components/Footer';
import MarkdownViewer from '../../components/shared-components/MarkdownViewer';

const MakdownPage = () => {
  const location = useLocation(); // 获取完整路径
  const { language } = useContext(SettingsContext); // 从 SettingsContext 获取语言设置

  // 保留完整路径，包括 /md/*
  const filePath = location.pathname || '';

  if (!filePath) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <p>Invalid file path. Please check the URL.</p>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      {/* 顶部导航 */}
      <CustomAppBar />

      {/* 主内容区域 */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          mt: { xs: 2, md: 12 },
          px: { xs: 2, sm: 4, md: 6 },
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            maxWidth: '850px',
            width: '100%',
            p: '3%',
            mt: { xs: 10, md: 0 },
            overflow: 'auto',
          }}
        >
          {/* 渲染 Markdown 文件 */}
          <MarkdownViewer filePath={filePath+".md"} target="md" />
        </Box>
      </Box>

      {/* 下部 Footer */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'background.default',
          borderTop: (theme) => ({
            xs: 'none',
            md: `1px solid ${theme.palette.divider}`,
          }),
          maxWidth: (theme) => theme.breakpoints.values.lg,
          width: '100%',
          mx: 'auto',
        }}
      >
        <Footer />
      </Box>
    </Box>
  );
};

export default MakdownPage;
