// pages/BlogPage.js
import React, { useContext, useEffect, useState} from 'react';
import { useParams } from 'react-router-dom'; // 用于获取路由参数
import { Box, Typography, CircularProgress } from '@mui/material';
import BlogService from '../../services/BlogService';
import CustomAppBar from '../../components/shared-components/AppBar';
import BlogViewer from '../../components/blog-components/BlogViewer';
import { SettingsContext } from '../../context/SettingsContext';
import Footer from '../../components/shared-components/Footer';

const BlogPage = () => {
  const { blogID } = useParams();
  const { language, themeMode } = useContext(SettingsContext);

  const [blogData, setBlogData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const data = await BlogService.getBlog(blogID);
        setBlogData(data);
      } catch (err) {
        if (language === "cn") {
          setError("404 \n 服务器访问失败 \n 无法加载博客");
        } else {
          setError("404 \n Interal Server Error \n Fail to load blog data");
        }
        
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [blogID, language]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          // alignItems: 'center',
          height: '100vh',
        }}
      >
        <Typography variant="h6" align="center" mt={16}>
            {language === 'cn' ? '正在加载内容...' : 'Loading content...'}
        </Typography>
        {/* <CircularProgress /> */}
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          // alignItems: 'center',
          height: '100vh',
        }}
      >
        <CustomAppBar />
        <Typography color="error" variant="h6" align="center" mt={16} style={{ whiteSpace: "pre-line" }}>
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* 上部导航栏 */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CustomAppBar />
      </Box>

      {/* BlogView 内容区域 */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          mt: 12,
          px: { xs: 2, sm: 4, md: 6 },
          overflow: 'auto',
        }}
      >
        <BlogViewer blogID={blogID} blogData={blogData}/>
      </Box>
      
      {/* 下部 Footer */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'background.default',
          borderTop: (theme) => ({
            xs: 'none', // 在 xs 屏幕无边框
            md: `1px solid ${theme.palette.divider}`, // 在 md 屏幕及以上显示边框
          }),
          maxWidth: (theme) => theme.breakpoints.values.lg, // 限制最大宽度为 lg
          width: '100%', // 确保宽度是 100% 的父元素宽度
          mx: 'auto', // 水平居中
        }}
      >
        <Footer />
      </Box>
      
    </Box>
  );
};

export default BlogPage;