import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, IconButton, Stack, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { SettingsContext } from '../../context/SettingsContext'; // 引入 SettingsContext
import { useUser } from '../../context/UserContext'; // 引入 UserContext
import BlogService from '../../services/BlogService';


const BlogViewer = ({ blogID, blogData }) => {
  const { themeMode, language } = useContext(SettingsContext); // 获取当前语言
  const { userState } = useUser(); // 从 UserContext 获取用户状态
  const [dialogOpen, setDialogOpen] = useState(false); // 管理对话框状态
  const [loading, setLoading] = useState(false); // 管理删除请求状态
  const navigate = useNavigate(); // 用于导航

  if (!blogData) {
    return null;
  }

  // 根据语言选择 title 和 content
  const title = language === "cn" ? blogData.cn_title : blogData.en_title;
  const content = language === "cn" ? blogData.cn_content : blogData.en_content;
  const { author, publish_date, last_updated_date } = blogData;

  let editorState;

  try {
    const rawContent = convertFromRaw(JSON.parse(content));
    editorState = EditorState.createWithContent(rawContent);
  } catch (error) {
    console.error("Invalid content format", error);
    editorState = EditorState.createEmpty();
  }

  // 打开对话框
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  // 关闭对话框
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // 确认删除
  const handleDelete = async () => {
    setLoading(true);
    try {
      await BlogService.deleteBlog(blogID, userState.access);
      setLoading(false);
      setDialogOpen(false);
      navigate('/blogs'); // 删除完成后导航到 /blogs
    } catch (error) {
      console.error("Error deleting blog", error);
      setLoading(false);
    }
  };

  // 点击编辑按钮时跳转到编辑页面
  const handleEdit = () => {
    navigate(`/editblog/${blogID}`);
  };


  return (
    <Box
      sx={{
        maxWidth: '850px',
        width: '100%',
        p: "3%",
        overflow: 'auto',
      }}
    >
      <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold', fontFamily: 'Georgia, serif' }}>
        {title}
      </Typography>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 4 }}>
        <Typography variant="body2" color="text.secondary">
          Published: {language === 'cn' ? new Date(publish_date).toLocaleDateString('zh-CN') : new Date(publish_date).toLocaleDateString('en-GB')} | Last Updated: {language === 'cn' ? new Date(last_updated_date).toLocaleDateString('zh-CN') : new Date(last_updated_date).toLocaleDateString('en-GB')}
        </Typography>
        {userState.is_staff && (
          <Stack direction="row" spacing={0}>
            <IconButton
              size="small"
              color="primary"
              onClick={handleEdit}
            >
              <EditOutlinedIcon />
            </IconButton>
            <IconButton
              size="small"
              color="secondary"
              onClick={handleDialogOpen}
            >
              <DeleteForeverOutlinedIcon />
            </IconButton>
          </Stack>
        )}
      </Stack>
      <Editor
        editorState={editorState}
        toolbarHidden
        readOnly
      />

      {/* 删除确认对话框 */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
      >
        <DialogTitle>{language === 'cn' ? '确认删除' : 'Confirm Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {language === 'cn' 
              ? '您确定要删除这篇博客吗？此操作无法撤销。'
              : 'Are you sure you want to delete this blog? This action cannot be undone.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} disabled={loading}>
            {language === 'cn' ? '取消' : 'Cancel'}
          </Button>
          <Button onClick={handleDelete} color="secondary" disabled={loading}>
            {loading ? (language === 'cn' ? '正在删除...' : 'Deleting...') : (language === 'cn' ? '删除' : 'Delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BlogViewer;
