import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography, Container } from '@mui/material';
import CustomAppBar from '../../components/shared-components/AppBar';
import { SettingsContext } from '../../context/SettingsContext';
import SimpleStockChart from '../../components/finance-components/SimpleStockChart';
import FinanceService from '../../services/FinanceService';
import Footer from '../../components/shared-components/Footer';

const Finance = () => {

  const { language, themeMode } = useContext(SettingsContext);
  const [stockData, setStockData] = useState({
    ASX200: null,
    SSE: null,
    SP500: null,
    FTSE: null,
  });

  const tickers = {
    ASX200: '^AXJO',
    SSE: '000001.SS',
    SP500: '^GSPC',
    FTSE: '^FTSE',
  };

  useEffect(() => {

    const fetchStockData = async () => {
      try {
        const responses = await Promise.all(
          Object.keys(tickers).map(async (key) => {
            const data = await FinanceService.get_stock_history_data(tickers[key]);
            return { key, data };
          })
        );

        const newData = responses.reduce((acc, { key, data }) => {
          acc[key] = data;
          return acc;
        }, {});

        setStockData(newData);
      } catch (error) {
        console.error('Failed to fetch stock data:', error);
      }
    };

    fetchStockData();
  }, []);

  const calculateChange = (data) => {
    if (!data || data.history.length < 2) return null;
    const lastClose = data.history[data.history.length - 1].Close;
    const secondLastClose = data.history[data.history.length - 2].Close;
    const change = lastClose - secondLastClose;
    const percentageChange = (change / secondLastClose) * 100;

    return { change, percentageChange };
  };

  const renderStockHeader = (title, data) => {
    const changeData = calculateChange(data);
    return (
      <Typography 
        variant="h6" 
        sx={{
          fontWeight: 700, 
          display: 'flex', 
          alignItems: 'center',
          justifyContent: 'center',
          color: themeMode === 'dark' ? 'white' : 'black'
        }}
      >
        {title}
        {changeData && (
          <Typography
            variant="subtitle1"
            component="span"
            sx={{
              marginLeft: 1,
              fontWeight: 600,
              color: changeData.change >= 0
                ? language === 'cn' ? '#ef5350' : '#26a69a' // Red : Green for 'cn'
                : language === 'cn' ? '#26a69a' : '#ef5350', // Green : Red for 'cn'
            }}
          >
            {changeData.change >= 0 ? '+' : ''}
            {changeData.change.toFixed(2)} / 
            {changeData.change >= 0 ? ' +' : ' '}
            {changeData.percentageChange.toFixed(2)}%
          </Typography>
        )}
      </Typography>
    );
  };
  

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <CustomAppBar />
        <Container maxWidth="lg" sx={{ flex: 1, padding: 2, mt: 12 }}>
          <Grid container spacing={2} sx={{ height: '100%' }}>
            {/* ASX200 */}
            <Grid item xs={12} sm={6} sx={{ height: { xs: '25%', sm: '50%' } }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  border: '1px solid #ccc',
                  borderRadius: 2,
                }}
              >
                {renderStockHeader('ASX 200', stockData.ASX200)}
                {stockData.ASX200 ? (
                  <SimpleStockChart data={stockData.ASX200} />
                ) : (
                  <Typography>Loading...</Typography>
                )}
              </Box>
            </Grid>

            {/* SSE */}
            <Grid item xs={12} sm={6} sx={{ height: { xs: '25%', sm: '50%' } }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  border: '1px solid #ccc',
                  borderRadius: 2,
                }}
              >
                {renderStockHeader('SSE (Shanghai)', stockData.SSE)}
                {stockData.SSE ? (
                  <SimpleStockChart data={stockData.SSE} />
                ) : (
                  <Typography>Loading...</Typography>
                )}
              </Box>
            </Grid>

            {/* S&P500 */}
            <Grid item xs={12} sm={6} sx={{ height: { xs: '25%', sm: '50%' } }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  border: '1px solid #ccc',
                  borderRadius: 2,
                }}
              >
                {renderStockHeader('S&P 500', stockData.SP500)}
                {stockData.SP500 ? (
                  <SimpleStockChart data={stockData.SP500} />
                ) : (
                  <Typography>Loading...</Typography>
                )}
              </Box>
            </Grid>

            {/* FTSE */}
            <Grid item xs={12} sm={6} sx={{ height: { xs: '25%', sm: '50%' } }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  border: '1px solid #ccc',
                  borderRadius: 2,
                }}
              >
                {renderStockHeader('FTSE', stockData.FTSE)}
                {stockData.FTSE ? (
                  <SimpleStockChart data={stockData.FTSE} />
                ) : (
                  <Typography>Loading...</Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* 下部 Footer */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'background.default',
          borderTop: (theme) => ({
            xs: 'none', // 在 xs 屏幕无边框
            md: `1px solid ${theme.palette.divider}`, // 在 md 屏幕及以上显示边框
          }),
          maxWidth: (theme) => theme.breakpoints.values.lg, // 限制最大宽度为 lg
          width: '100%', // 确保宽度是 100% 的父元素宽度
          mx: 'auto', // 水平居中
        }}
      >
        <Footer />
      </Box>
    </Box>
  );
};

export default Finance;
