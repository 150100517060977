import React, { useContext, useState, useEffect } from "react";
import { Box, Tabs, Tab, TextField, Button } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import CustomAppBar from "../../components/shared-components/AppBar";
import DraftJsWysiwyg from "../../components/blog-components/Draft-js-wysiwyg";
import BlogService from "../../services/BlogService";
import { SettingsContext } from "../../context/SettingsContext";
import { useUser } from "../../context/UserContext";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";

const EditBlogPage = () => {
  const navigate = useNavigate();
  const { blogID } = useParams();
  const { themeMode, language } = useContext(SettingsContext);
  const { userState } = useUser();

  const [activeTab, setActiveTab] = useState(0);
  const [tabsData, setTabsData] = useState({
    CN: { title: "", content: EditorState.createEmpty() },
    EN: { title: "", content: EditorState.createEmpty() },
  });

  useEffect(() => {
    // 获取博客数据
    const fetchBlogData = async () => {
      try {
        const blogData = await BlogService.getBlog(blogID);

        // 设置标题和内容
        setTabsData({
          CN: {
            title: blogData.cn_title,
            content: EditorState.createWithContent(
              convertFromRaw(JSON.parse(blogData.cn_content))
            ),
          },
          EN: {
            title: blogData.en_title,
            content: EditorState.createWithContent(
              convertFromRaw(JSON.parse(blogData.en_content))
            ),
          },
        });
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    fetchBlogData();
  }, [blogID]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleInputChange = (langKey, field, value) => {
    setTabsData((prev) => ({
      ...prev,
      [langKey]: { ...prev[langKey], [field]: value },
    }));
  };

  const handleContentChange = (langKey, editorState) => {
    setTabsData((prev) => ({
      ...prev,
      [langKey]: { ...prev[langKey], content: editorState },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSubmit = {
      cn_title: tabsData.CN.title,
      en_title: tabsData.EN.title,
      author: userState.username,
      cn_content: JSON.stringify(
        convertToRaw(tabsData.CN.content.getCurrentContent())
      ),
      en_content: JSON.stringify(
        convertToRaw(tabsData.EN.content.getCurrentContent())
      ),
    };

    try {
      await BlogService.editBlog(blogID, dataToSubmit, userState.access);
      console.log("Blog edited successfully");
      navigate(`/blog/${blogID}`);
    } catch (error) {
      console.error("Error editing blog:", error);
    }
  };

  const labels = {
    title: language === "cn" ? "标题" : "Title",
    content: language === "cn" ? "内容" : "Content",
    submit: language === "cn" ? "提交" : "Submit",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        bgcolor: themeMode?.palette?.background?.default || "#f5f5f5",
      }}
    >
      <CustomAppBar />
      <Box
        sx={{
          flex: "1 0 auto",
          display: "flex",
          flexDirection: "column",
          maxWidth: 1000,
          width: "100%",
          mt: 12,
          mx: "auto",
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{ mb: 3 }}
          variant="fullWidth"
        >
          <Tab label="CN" />
          <Tab label="EN" />
        </Tabs>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <TextField
            label={activeTab === 0 ? `${labels.title} (CN)` : `${labels.title} (EN)`}
            variant="outlined"
            value={activeTab === 0 ? tabsData.CN.title : tabsData.EN.title}
            onChange={(e) =>
              handleInputChange(
                activeTab === 0 ? "CN" : "EN",
                "title",
                e.target.value
              )
            }
            fullWidth
            sx={{ mb: 3 }}
          />

          <DraftJsWysiwyg
            editorState={activeTab === 0 ? tabsData.CN.content : tabsData.EN.content}
            onEditorStateChange={(editorState) =>
              handleContentChange(activeTab === 0 ? "CN" : "EN", editorState)
            }
            placeholder={labels.content}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button type="submit" variant="contained" color="primary">
              {labels.submit}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EditBlogPage;
