import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { Fragment, useContext } from 'react';
import { Avatar } from '@mui/material';
import Typography from '@mui/material/Typography';

import { SettingsContext } from '../../context/SettingsContext';

function Copyright({ language }) {
  return (
    <Typography 
      variant="body2"
      sx={{ 
        color: 'text.secondary', 
        mt: 1,
        typography: { md: 'body1' }  // 在 md 断点改变为 body1
      }}
    >
      {"All Rights Reserved © "}
      <Link 
        color="text.secondary" 
        href="https://xiluo.net/"
        sx={{ typography: { md: 'body1' } }}
      >
        xiluo.net
      </Link>
      &nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  const { language, themeMode } = useContext(SettingsContext);

  // 动态路径
  const terms_path = language === "cn" ? "/md/cn/terms" : "/md/en/terms";
  const privacy_path = language === "cn" ? "/md/cn/privacy" : "/md/en/privacy";

  return (
    <Fragment>
      <Container
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { md: 'space-between' },
          alignItems: { xs: 'flex-start', md: 'center' },
          px: { xs: 4 },
          py: { xs: 2, sm: 5 },
        }}
      >
        {/* 左侧：头像和Link 区域 */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: 2, sm: 3 },
            textAlign: 'left',
            alignItems: 'flex-start',
            mb: { xs: 2, md: 0 },
            pb: 2,
            width: '100%',
            borderBottom: (theme) => ({
              xs: `1px solid ${theme.palette.divider}`,
              sm: 'none',
            }),
          }}
        >
          <Avatar
            src={require('../../assets/img/steven_cartoon.jpeg')}
            alt="Steven Logo"
            sx={{
              width: 30,
              height: 30,
              overflow: 'hidden',
            }}
          />

          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            {[
              { path: '/', labelCn: '首页', labelEn: 'Home' },
              { path: '/notes', labelCn: '笔记', labelEn: 'Notes' },
              { path: '/blogs', labelCn: '博客', labelEn: 'Blogs' },
              { path: '/projects', labelCn: '项目', labelEn: 'Projects' },
              { path: '/fin', labelCn: '金融面板', labelEn: 'Finance' },
              { path: '/profile', labelCn: '个人简介', labelEn: 'Profile' }
            ].map(({ path, labelCn, labelEn }) => (
              <Link 
                key={path}
                color="text.secondary" 
                variant="body2"
                href={path}
                sx={{ typography: { md: 'body1' } }}
              >
                {language === "cn" ? labelCn : labelEn}
              </Link>
            ))}
          </Box>
        </Box>

        {/* 右侧：Legal 和版权 */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: { xs: 'flex-start', md: 'flex-end' },
            textAlign: { xs: 'left', md: 'right' },
            gap: 1,
            mt: { md: 2 },
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <Typography 
              variant="body2"
              sx={{ 
                fontWeight: 'medium',
                typography: { md: 'body1' }
              }}
            >
              {language === "cn" ? "法律" : "Legal"}
            </Typography>
            {[
              { label: language === "cn" ? "条款" : "Terms", path: terms_path },
              { label: language === "cn" ? "隐私" : "Privacy", path: privacy_path }
            ].map(({ label, path }) => (
              <Link 
                key={label}
                color="text.secondary" 
                variant="body2"
                href={path} // 动态路径
                sx={{ typography: { md: 'body1' } }}
              >
                {label}
              </Link>
            ))}
          </Box>

          <Copyright language={language} />
        </Box>
      </Container>
    </Fragment>
  );
}
