import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';

const ProtectedRoute = ({ requiredCondition, redirectPath = "/", children }) => {
  const { userState } = useUser();

  // 验证条件是否满足
  const hasAccess = requiredCondition(userState);

  if (!hasAccess) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default ProtectedRoute;
