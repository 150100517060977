// ./services/BlogService.js
import api from './api';

const BlogService = {
  // 获取指定 ID 的博客
  getBlog: async (blogId) => {
    try {
      const response = await api.get(`/blog/${blogId}/`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching blog:', error);
      throw error;
    }
  },

  // 添加新博客
  addBlog: async (blogData, accessToken) => {
    try {
      const response = await api.post('/add_blog/', blogData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error adding blog:', error);
      throw error;
    }
  },

  // 编辑博客（支持部分更新）
  editBlog: async (blogId, blogData, accessToken, isPartial = true) => {
    try {
      console.log(blogData)
      const method = isPartial ? 'patch' : 'put';
      const response = await api[method](`/edit_blog/${blogId}/`, blogData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error editing blog:', error);
      throw error;
    }
  },

  // 获取博客元数据（可选分页参数）
  getMeta: async (page = null, pageSize = null) => {
    try {
      const requestData = {};
      if (page !== null && pageSize !== null) {
        requestData.page = page;
        requestData.page_size = pageSize;
      }

      const response = await api.post('/get_blog_meta/', requestData, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching blog metadata:', error);
      throw error;
    }
  },

  // 删除博客
  deleteBlog: async (blogId, accessToken) => {
    try {
      const response = await api.delete(`/delete_blog/${blogId}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.status;
    } catch (error) {
      console.error('Error deleting blog:', error);
      throw error;
    }
  },

  // 获取草稿
  getDraft: async (username, accessToken) => {
    try {
      const response = await api.get(`/get_draft_blog/${username}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching draft:', error);
      throw error;
    }
  },

  // 保存草稿
  saveDraft: async (draftData, accessToken) => {
    try {
      const response = await api.post('/save_draft_blog/', draftData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error saving draft:', error);
      throw error;
    }
  },
};

export default BlogService;
