import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// 引入 UserProvider
import { UserProvider } from '../context/UserContext'; 
// 引入 ProtectedRoute
import ProtectedRoute from './ProtectedRoute'; 

// Main Pages
import Home from '../pages/main-pages/Home';
import Notes from '../pages/main-pages/Notes';
import Blogs from '../pages/main-pages/Blogs';
import Projects from '../pages/main-pages/Projects';
import Finance from '../pages/main-pages/Finance';
import Profile from '../pages/main-pages/Profile';

// Note and Blog Pages
import NotePage from '../pages/note-pages/NotePage';
import BlogPage from '../pages/blog-pages/BlogPage';
import AddBlogPage from '../pages/blog-pages/BlogPage';
import EditBlogPage from '../pages/blog-pages/EditBlogPage';

// Ohter Pages
import NotFound from '../pages/other-pages/NotFound';
import Signup from '../pages/legacy-pages/Signup';
import MakdownPage from '../pages/other-pages/MarkdownPage';

const AppRoutes = () => {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/notes" element={<Notes />} />
          <Route path="/notes-cn/:filePath" element={<NotePage />} />
          <Route path="/notes-en/:filePath" element={<NotePage />} />
          <Route path="/md/*" element={<MakdownPage/>}/>
          <Route path="/fin" element={<Finance />} />
          {/* <Route path="/signup" element={<Signup />} /> */}
          <Route path="/profile" element={<Profile />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/blog/:blogID" element={<BlogPage />} />

          {/* 受保护的路由：仅 is_staff 为 true 的用户可访问 */}
          <Route 
            path="/addblog" 
            element={
              <ProtectedRoute 
                requiredCondition={(userState) => userState.is_staff}
              >
                <AddBlogPage />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/editblog/:blogID" 
            element={
              <ProtectedRoute 
                requiredCondition={(userState) => userState.is_staff}
              >
                <EditBlogPage />
              </ProtectedRoute>
            } 
          />
          {/* 404 页面，path="*" 用来匹配所有未定义的路由 */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </UserProvider>
  );
};

export default AppRoutes;
