import React, { useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom'; // 确保从 react-router-dom 导入 useParams
import { Box, Container } from '@mui/material';
import CustomAppBar from '../../components/shared-components/AppBar';
import { SettingsContext } from '../../context/SettingsContext'; // 引入 SettingsContext
import Footer from '../../components/shared-components/Footer';
import MarkdownViewer from "../../components/shared-components/MarkdownViewer";

const NotePage = () => {
  const { filePath: paramFilePath } = useParams();
  const { pathname } = useLocation();
  const { language, themeMode } = useContext(SettingsContext); // 从 SettingsContext 获取 themeMode

  const filePath = language === "cn"
    ? `notes-cn/${paramFilePath || pathname}`
    : `notes-en/${paramFilePath || pathname}`;

  if (!pathname) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <p>Invalid file path. Please check the URL.</p>
      </Box>  
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      {/* 顶部导航 */}
      <CustomAppBar />

      {/* 主内容区域 */}
      <Box
        sx={{
          flex: 1, // 占满剩余空间
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start', // 内容从顶部开始
          mt: { xs: 2, md: 12}, // 与导航栏分隔
          px: { xs: 2, sm: 4, md: 6 }, // 响应式内边距
          overflow: 'auto', // 滚动行为
        }}
      >
        <Box
          sx={{
            maxWidth: '850px', // 最大宽度
            width: '100%', // 自适应宽度
            p: "3%",  
            mt: { xs: 10, md: 0 },
            overflow: 'auto', // 添加滚动
          }}
        >
          {/* 渲染 Markdown 文件 */}
          {/* <MarkdownViewer filePath={"Notes/"+filePath} isFromNotePage={true} /> */}
          <MarkdownViewer filePath={filePath} target="note_md" />
        </Box>
      </Box>

      {/* 下部 Footer */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'background.default',
          borderTop: (theme) => ({
            xs: 'none', // 在 xs 屏幕无边框
            md: `1px solid ${theme.palette.divider}`, // 在 md 屏幕及以上显示边框
          }),
          maxWidth: (theme) => theme.breakpoints.values.lg, // 限制最大宽度为 lg
          width: '100%', // 确保宽度是 100% 的父元素宽度
          mx: 'auto', // 水平居中
        }}
      >
        <Footer />
      </Box>
      
    </Box>
  );
};

export default NotePage;
