import React, { createContext, useState, useMemo, useEffect } from 'react';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';

// 创建 Settings 上下文
export const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const [language, setLanguage] = useState(() => {
    return localStorage.getItem('language') || 'en';
  });
  const [themeMode, setThemeMode] = useState(() => {
    return localStorage.getItem('themeMode') || 'light';
  });

  useEffect(() => {
    if (!localStorage.getItem('language')) {
      const systemLanguage = navigator.language.startsWith('zh') ? 'cn' : 'en';
      setLanguage(systemLanguage);
      localStorage.setItem('language', systemLanguage);
    }
  }, []);

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    if (!localStorage.getItem('themeMode')) {
      const systemThemeMode = darkModeMediaQuery.matches ? 'dark' : 'light';
      setThemeMode(systemThemeMode);
      localStorage.setItem('themeMode', systemThemeMode);
    }

    const handleChange = (e) => {
      const newThemeMode = e.matches ? 'dark' : 'light';
      setThemeMode(newThemeMode);
      localStorage.setItem('themeMode', newThemeMode);
    };
    darkModeMediaQuery.addEventListener('change', handleChange);

    return () => {
      darkModeMediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  const toggleLanguage = () => {
    const newLanguage = language === 'en' ? 'cn' : 'en';
    setLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
  };

  const toggleTheme = () => {
    const newThemeMode = themeMode === 'light' ? 'dark' : 'light';
    setThemeMode(newThemeMode);
    localStorage.setItem('themeMode', newThemeMode);
  };

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeMode,
          background: {
            default: themeMode === 'dark' ? '#1a1a1a' : '#f5f5f5',
            paper: themeMode === 'dark' ? '#181818' : '#f8f8f8',
          },
        },
      }),
    [themeMode]
  );

  // 动态生成滚动条样式
  const scrollbarStyle = useMemo(() => {
    const trackColor = themeMode === 'dark' ? '#1a1a1a' : '#f5f5f5';
    const thumbColor = themeMode === 'dark' ? '#3a3a3a' : '#c0c0c0';
    const hoverColor = themeMode === 'dark' ? '#5a5a5a' : '#a0a0a0';

    return `
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      ::-webkit-scrollbar-track {
        background: ${trackColor};
      }
      ::-webkit-scrollbar-thumb {
        background: ${thumbColor};
        border-radius: 4px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: ${hoverColor};
      }
    `;
  }, [themeMode]);

  return (
    <SettingsContext.Provider value={{ language, themeMode, toggleLanguage, toggleTheme }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <style>{scrollbarStyle}</style> {/* 引入滚动条样式 */}
        {children}
      </ThemeProvider>
    </SettingsContext.Provider>
  );
};
